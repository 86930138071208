import { useSession } from 'next-auth/react'
import {
  createContext,
  Dispatch,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { User, Impersonator } from 'types/next-auth'
import getFullName from 'utils/getFullName'
import logger from 'utils/logger'
import {
  AUTH_SESSION_TOKEN,
  AUTH_SESSION_USER_ID,
} from 'utils/localStorageKeys'
import { H } from 'highlight.run'
import { env } from 'env/client.mjs'
import useTidio from 'hooks/useTidio'

export type Profile = Pick<
  User,
  'id' | 'firstName' | 'lastName' | 'username' | 'picture'
> & {
  fullName?: string | null
  entityName?: string | null
  publicName?: string | null
}

export const setToken = (token: string) => {
  window.localStorage.setItem(AUTH_SESSION_TOKEN, token)
}

export const getToken = (): string | null => {
  return window.localStorage.getItem(AUTH_SESSION_TOKEN)
}

export const resetToken = () => {
  window.localStorage.removeItem(AUTH_SESSION_TOKEN)
}

export const setUserID = (userID: number) => {
  window.localStorage.setItem(AUTH_SESSION_USER_ID, userID.toString())
}

export const getUserID = (): number | null => {
  const stored = window.localStorage.getItem(AUTH_SESSION_USER_ID)

  return stored ? parseInt(stored, 10) : null
}

export const resetUserID = () => {
  window.localStorage.removeItem(AUTH_SESSION_USER_ID)
}

const Context = createContext<{
  account?: User
  setAccount: Dispatch<User>
  impersonator?: Impersonator
}>({
  account: undefined,
  setAccount: () => undefined,
  impersonator: undefined,
})

type UseAuth = {
  account?: User
  authenticating: boolean
  isAuthenticated: boolean
  setAccount: (newAccount: User) => void
  setPartialAccount: (partialAccount: Partial<User>) => void
  impersonator?: Impersonator
}

type UseAuthAuthenticating = UseAuth & {
  authenticating: true
  isAuthenticated: false
  account: undefined
}

type UseAuthAuthenticated = UseAuth & {
  authenticating: false
  isAuthenticated: true
  account: User
}

type UseAuthAnonymous = UseAuth & {
  authenticating: false
  isAuthenticated: false
  account: undefined
}

export const useAuth = ():
  | UseAuthAuthenticating
  | UseAuthAuthenticated
  | UseAuthAnonymous => {
  const session = useSession()
  const { account, setAccount, impersonator } = useContext(Context)

  return {
    account,
    // Note: this is needed because AuthProvider sets the account once the status changes to 'authenticated'
    authenticating: (session.status === 'loading' ||
      (session.status === 'authenticated' && !account)) as any,
    isAuthenticated: (session.status === 'authenticated' && !!account) as any,
    setAccount: (newAccount) => {
      setAccount(newAccount)
    },
    setPartialAccount: (partialAccount: Partial<User>) => {
      if (!account) {
        throw new Error(
          `Cannot set partial Account since Account does not exist`,
        )
      }

      const newAccount = {
        ...account,
        ...partialAccount,
      }

      setAccount(newAccount)
    },
    impersonator,
  }
}

const AuthProvider = ({ children }: any) => {
  const session = useSession()
  const [account, setAccount] = useState<User | undefined>(session.data?.user)
  const [impersonator, setImpersonator] = useState<Impersonator | undefined>(
    session.data?.impersonator,
  )
  const tidio = useTidio()

  const memoizedSession = useMemo(() => session, [session])

  useEffect(() => {
    logger.debug('session change detected from auth.client', { session })

    if (memoizedSession?.data?.token) {
      setToken(memoizedSession.data.token)
    } else {
      resetToken()
    }

    if (memoizedSession?.data?.user) {
      setUserID(memoizedSession.data.user.id)
      setAccount(memoizedSession.data.user)
      setImpersonator(memoizedSession.data.impersonator)

      import('clarity-js').then(({ clarity }) => {
        if (memoizedSession.data.user) {
          clarity.consent()
          clarity.identify(memoizedSession.data.user.id.toString())
          clarity.set('user_id', memoizedSession.data.user.id.toString())
        }
      })

      H.identify(
        memoizedSession.data.user.email ||
          memoizedSession.data.user.id.toString(),
        {
          id: memoizedSession.data.user.id,
          name: getFullName(
            memoizedSession.data.user.firstName,
            memoizedSession.data.user.lastName,
          ),
          email: memoizedSession.data.user.email || 'N/A',
          isSeller: memoizedSession.data.user.isSeller,
          hasCompletedLegalInformation:
            memoizedSession.data.user.hasCompletedLegalInformation,
          hasAcceptedTerms: memoizedSession.data.user.hasAcceptedTerms,
          hasCompletedProfile: memoizedSession.data.user.hasCompletedProfile,
          country: memoizedSession.data.user.country || 'N/A',
          type: memoizedSession.data.user.type || 'N/A',
        },
      )

      if (env.NEXT_PUBLIC_RAYGUN_API_KEY) {
        import('raygun4js').then(({ default: rg4js }) => {
          if (memoizedSession.data.user) {
            rg4js('setUser', {
              identifier: memoizedSession.data.user.id.toString(),
              isAnonymous: false,
              email: memoizedSession.data.user.email || '',
              firstName: memoizedSession.data.user.firstName || '',
              fullName: getFullName(
                memoizedSession.data.user.firstName,
                memoizedSession.data.user.lastName,
              ),
            })
          }
        })
      }

      tidio.identify({
        id: memoizedSession.data.user.id.toString(),
        name: getFullName(
          memoizedSession.data.user.firstName,
          memoizedSession.data.user.lastName,
        ),
        email: memoizedSession.data.user.email || 'N/A',
      })
    } else {
      resetUserID()
    }
  }, [memoizedSession.status])

  return (
    <Context.Provider value={{ account, setAccount, impersonator }}>
      {children}
    </Context.Provider>
  )
}

export default AuthProvider
