import {
  createContext,
  useState,
  useContext,
  type ReactNode,
  type FC,
  useEffect,
} from 'react'
import cc from 'classcat'
import ms from 'ms'
import IconClose from 'assets/icons/x.svg'
import IconCheck from 'assets/icons/checkmark.svg'
import IconWarning from 'assets/icons/warning.svg'
import styles from './index.module.scss'
import Button from 'components/Button'
import useLogger from 'hooks/useLogger'

export type Toast = {
  type: Type
  message?: ReactNode | FC
  position?: 'top' | 'button'
  preventAutoClose?: boolean
  title?: string
  preventClose?: boolean
  onClose?: () => void
  allowToQueue?: boolean
}

type ContextValue = {
  notify: (toast: Toast) => void
  dismiss: () => void
  confirm: (
    partialToast: Pick<Toast, 'title' | 'message'>,
    opts: {
      onConfirm: () => void
      onCancel?: () => void
    },
  ) => void
}

const Context = createContext<ContextValue>({
  notify: () => {
    throw new Error(`Cannot use Toast Message outside "ToastProvider"`)
  },
  dismiss: () => {
    throw new Error(`Cannot use Toast Message outside "ToastProvider"`)
  },
  confirm: () => {
    throw new Error(`Cannot use Toast Message outside "ToastProvider"`)
  },
})

export type Type = 'info' | 'success' | 'failure' | 'warning' | 'neutral'

type ToastProviderProps = {
  children: any
}

// eslint-disable-next-line react/display-name
export const ToastProvider = ({ children }: ToastProviderProps) => {
  const { log } = useLogger()
  const [queue, setQueue] = useState<Toast[]>([])
  const [state, setState] = useState<null | Toast>(null)

  const notify = (toast: Toast) => {
    if (state && toast.allowToQueue) {
      setQueue((currentQueue) => [...currentQueue, toast])
    }

    if (!toast.preventAutoClose) {
      window.setTimeout(() => {
        setState(null)
      }, ms('5s'))
    }

    setState(toast)
  }

  const dismiss = () => {
    if (queue.length > 0) {
      const [newToast, ...rest] = queue

      if (newToast) {
        setState(newToast)

        if (rest.length > 0) {
          setQueue(rest)
        }
      }
    } else {
      setState(null)
    }
  }

  const confirm = (
    partialToast: Pick<Toast, 'title' | 'message'>,
    {
      onConfirm,
      onCancel,
    }: {
      onConfirm: () => void
      onCancel?: () => void
    },
  ) => {
    notify({
      type: 'neutral',
      preventAutoClose: true,
      preventClose: true,
      title: partialToast.title || 'Are you sure?',
      message: (
        <div className="px-4">
          <>{partialToast.message}</>
          <div className="grid grid-cols-2 gap-4 pt-8">
            <Button
              type="button"
              $fluid
              onClick={() => {
                onConfirm()
                dismiss()
              }}
            >
              Continue
            </Button>
            <Button
              type="button"
              $fluid
              $type="tertiary"
              onClick={() => {
                onCancel?.()
                dismiss()
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      ),
    })
  }

  useEffect(() => {
    if (state) {
      import('clarity-js').then(({ clarity }) => {
        clarity.set('toast', state.type)
      })

      if (state.type === 'failure') {
        log(
          'error',
          typeof state.title === 'string'
            ? state.title
            : typeof state.message === 'string'
            ? state.message
            : 'Unknown Toast Error',
        )
      }
    }
  }, [state])

  return (
    <Context.Provider
      value={{
        notify,
        dismiss,
        confirm,
      }}
    >
      {children}

      {state && (
        <>
          <div
            className="fixed inset-0 z-10 bg-black bg-opacity-50 backdrop-filter backdrop-blur"
            onClick={() => {
              if (!state.preventClose) {
                dismiss()
              }
            }}
          />
          <div className="z-11 w-full max-w-lg md:px-2 py-2 px-8 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 fixed">
            <ToastMessage
              type={state.type}
              message={state.message}
              title={state.title}
              animated
              onClick={() => {
                dismiss()
              }}
              preventClose={state.preventClose}
            />
          </div>
        </>
      )}
    </Context.Provider>
  )
}

export const useToast = (): ContextValue => useContext(Context)

const titleMap: {
  [key: string]: {
    message: string
    bgColor: string
  }
} = {
  success: {
    message: 'Saved',
    bgColor: 'bg-action-success',
  },
  failure: {
    message: 'Error',
    bgColor: 'bg-action-fail',
  },
  info: {
    message: 'Info',
    bgColor: 'bg-primary',
  },
  warning: {
    message: 'Warning!',
    bgColor: 'bg-action-warning',
  },
}

type ToastMessageProps = {
  type: Type
  message: React.ReactNode | React.FC
  animated?: boolean
  title?: string
  onClick: () => void
  preventClose?: boolean
}

export const ToastMessage = ({
  type,
  message,
  animated,
  title,
  preventClose,
  onClick,
}: ToastMessageProps) => {
  const getTypeIcon = (type: string) => {
    switch (type) {
      case 'success':
        return <IconCheck width={28} className="fill-white" />
      case 'failure':
        return <IconClose width={18} className="fill-white" />
      case 'info':
      case 'warning':
        return <IconWarning width={24} className="fill-white" />
      default:
        return null
    }
  }
  const MessageComponent = typeof message === 'function' ? message : null
  const icon = getTypeIcon(type)
  const titleText = title ? title : type && titleMap?.[type]?.message

  return (
    <div
      className={cc([
        'flex flex-col justify-center items-center',
        styles.toast && {
          [styles.toast]: animated,
        },
      ])}
    >
      <div
        className="bg-white pb-8 rounded-lg shadow-2xl w-full"
        data-testid="toast-message"
      >
        {/* Header */}
        <div className="relative h-16 flex flex-col items-center ">
          {icon && (
            <div
              className={cc([
                'absolute rounded-full h-12 w-12 flex items-center justify-center border-4 border-white bottom-0 top-5',
                type && titleMap?.[type]?.['bgColor'],
              ])}
              onClick={onClick}
            >
              {icon}
            </div>
          )}

          <div className="flex items-center justify-center text-lg text-white font-bold h-12 bg-gradient-to-r from-primary to-secondary w-full rounded-t-lg">
            {!icon && titleText}
          </div>
        </div>

        {/* Body */}
        <div className="my-5 text-blackf text-center">
          {MessageComponent ? (
            <MessageComponent />
          ) : (
            <>
              {icon && (
                <div className="text-xl text-black font-bold mt-1">
                  {titleText}
                </div>
              )}

              <div className="text-gray-700 my-1 px-2">{message as any}</div>
            </>
          )}
        </div>

        {/* Close */}
        {!preventClose && (
          <button
            className="absolute top-0 md:right-0 right-4 flex justify-center items-center bg-white rounded-full w-8 h-8"
            onClick={onClick}
            data-testid="button-close-toast"
          >
            <IconClose className="w-3 h-3" />
          </button>
        )}
      </div>
    </div>
  )
}

ToastMessage.defaultProps = {
  type: 'info',
}
