// @ts-check
import safeNumber from 'utils/safeNumber'
import { z } from 'zod'

/**
 * Specify your server-side environment variables schema here.
 * This way you can ensure the app isn't built with invalid env vars.
 */
export const serverSchema = z.object({
  DATABASE_URL: z.string().url(),
  NODE_ENV: z.enum(['development', 'test', 'production']),
  NEXTAUTH_SECRET: z.string(),
  PROJECT_URL: z.string().url(),
  DISCORD_CLIENT_ID: z.string(),
  DISCORD_CLIENT_SECRET: z.string(),
  GOOGLE_CLIENT_ID: z.string(),
  GOOGLE_CLIENT_SECRET: z.string(),
  FACEBOOK_CLIENT_ID: z.string(),
  FACEBOOK_CLIENT_SECRET: z.string(),
  HASURA_ADMIN_SECRET: z.string(),
  STRIPE_SECRET_KEY: z.string(),
  SLACK_WEBHOOK_URL: z.string().optional(),
  REVALIDATE_TOKEN: z.string(),
  INFX_API_SECRET: z.string(),
  SKIP_BUILD_STATIC_GENERATION: z.string().optional(),
  I18NEXUS_API_KEY: z.string(),
  APP_ENV: z.string(),
  AWS_ACCESS_KEY_ID: z.string(),
  AWS_SECRET_ACCESS_KEY: z.string(),
  AWS_REGION: z.string(),
  AWS_S3_BUCKET: z.string(),
  HIGHLIGHT_SOURCEMAPS_API_KEY: z.string().optional(),
  EMAIL_SERVER_USER: z.string(),
  EMAIL_SERVER_PASSWORD: z.string(),
  EMAIL_SERVER_HOST: z.string(),
  EMAIL_SERVER_PORT: z.string(),
  EMAIL_FROM: z.string(),
  FORCE_SOURCE_MAPS: z.string().optional(),
  RECAPTCHA_SECRET_KEY: z.string(),
  VERIFYMAIL_API_KEY: z.string(),
})

/**
 * Specify your client-side environment variables schema here.
 * This way you can ensure the app isn't built with invalid env vars.
 * To expose them to the client, prefix them with `NEXT_PUBLIC_`.
 */
export const clientSchema = z.object({
  // NEXT_PUBLIC_BAR: z.string(),
  NEXT_PUBLIC_FEATURE_TESTING_PROJECT: z.boolean(),
  NEXT_PUBLIC_FEATURE_TESTING_SEARCH: z.boolean(),
  NEXT_PUBLIC_FEATURE_TESTING_REVIEW: z.boolean(),
  NEXT_PUBLIC_FEATURE_TESTING_LAST_SEEN: z.boolean(),
  NEXT_PUBLIC_FEATURE_TESTING_PROPOSALS: z.boolean(),
  NEXT_PUBLIC_FEATURE_TESTING_PACKAGES_SUBSCRIPTIONS: z.boolean(),
  NEXT_PUBLIC_FEATURE_TESTING_VERIFY: z.boolean(),
  NEXT_PUBLIC_FEATURE_TESTING_ADS: z.boolean(),
  NEXT_PUBLIC_FEATURE_TESTING_AFFILIATE: z.boolean(),
  NEXT_PUBLIC_FEATURE_TESTING_PRO: z.boolean(),
  NEXT_PUBLIC_STRIPE_PUBLIC_KEY: z.string(),
  NEXT_PUBLIC_GRAPHQL_URL: z.string(),
  NEXT_PUBLIC_GRAPHQL_WS_URL: z.string(),
  NEXT_PUBLIC_GA_MEASUREMENT_ID: z.string().optional(),
  NEXT_PUBLIC_INFLUENCER_PROFILE_PIC: z.string(),
  NEXT_PUBLIC_FINGERPRINT_API_KEY: z.string().optional(),
  NEXT_PUBLIC_CLARITY_KEY: z.string().optional(),
  NEXT_PUBLIC_FACEBOOK_CLIENT_ID: z.string(),
  NEXT_PUBLIC_ICONO_PAY_USER_ID: z.number(),
  NEXT_PUBLIC_FIREBASE_API_KEY: z.string(),
  NEXT_PUBLIC_FIREBASE_PROJECT_ID: z.string(),
  NEXT_PUBLIC_FIREBASE_APP_ID: z.string(),
  NEXT_PUBLIC_FIREBASE_DATABASE_URL: z.string(),
  NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID: z.string().optional(),
  NEXT_PUBLIC_RAYGUN_API_KEY: z.string().optional(),
  NEXT_PUBLIC_COMMIT_HASH: z.string().optional(),
  NEXT_PUBLIC_SENTRY_DSN: z.string().optional(),
  NEXT_PUBLIC_TIDIO_KEY: z.string().optional(),
  NEXT_PUBLIC_RECAPTCHA_SITE_KEY: z.string().optional(),
  NEXT_PUBLIC_POSTHOG_KEY: z.string().optional(),
  NEXT_PUBLIC_POSTHOG_HOST: z.string().optional(),
})

/**
 * You can't destruct `process.env` as a regular object, so you have to do
 * it manually here. This is because Next.js evaluates this at build time,
 * and only used environment variables are included in the build.
 * @type {{ [k in keyof z.infer<typeof clientSchema>]: z.infer<typeof clientSchema>[k] | undefined }}
 */
export const clientEnv = {
  NEXT_PUBLIC_FEATURE_TESTING_PROJECT:
    process.env.NEXT_PUBLIC_FEATURE_TESTING_PROJECT === 'true',
  NEXT_PUBLIC_FEATURE_TESTING_SEARCH:
    process.env.NEXT_PUBLIC_FEATURE_TESTING_SEARCH === 'true',
  NEXT_PUBLIC_FEATURE_TESTING_REVIEW:
    process.env.NEXT_PUBLIC_FEATURE_TESTING_REVIEW === 'true',
  NEXT_PUBLIC_FEATURE_TESTING_LAST_SEEN:
    process.env.NEXT_PUBLIC_FEATURE_TESTING_LAST_SEEN === 'true',
  NEXT_PUBLIC_FEATURE_TESTING_PROPOSALS:
    process.env.NEXT_PUBLIC_FEATURE_TESTING_PROPOSALS === 'true',
  NEXT_PUBLIC_FEATURE_TESTING_PACKAGES_SUBSCRIPTIONS:
    process.env.NEXT_PUBLIC_FEATURE_TESTING_PACKAGES_SUBSCRIPTIONS === 'true',
  NEXT_PUBLIC_FEATURE_TESTING_VERIFY:
    process.env.NEXT_PUBLIC_FEATURE_TESTING_VERIFY === 'true',
  NEXT_PUBLIC_FEATURE_TESTING_ADS:
    process.env.NEXT_PUBLIC_FEATURE_TESTING_ADS === 'true',
  NEXT_PUBLIC_FEATURE_TESTING_AFFILIATE:
    process.env.NEXT_PUBLIC_FEATURE_TESTING_AFFILIATE === 'true',
  NEXT_PUBLIC_FEATURE_TESTING_PRO:
    process.env.NEXT_PUBLIC_FEATURE_TESTING_PRO === 'true',
  NEXT_PUBLIC_STRIPE_PUBLIC_KEY: process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY,
  NEXT_PUBLIC_GRAPHQL_URL: process.env.NEXT_PUBLIC_GRAPHQL_URL,
  NEXT_PUBLIC_GRAPHQL_WS_URL: process.env.NEXT_PUBLIC_GRAPHQL_WS_URL,
  NEXT_PUBLIC_GA_MEASUREMENT_ID: process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID,
  NEXT_PUBLIC_INFLUENCER_PROFILE_PIC:
    process.env.NEXT_PUBLIC_INFLUENCER_PROFILE_PIC,
  NEXT_PUBLIC_FINGERPRINT_API_KEY: process.env.NEXT_PUBLIC_FINGERPRINT_API_KEY,
  NEXT_PUBLIC_CLARITY_KEY: process.env.NEXT_PUBLIC_CLARITY_KEY,
  NEXT_PUBLIC_FACEBOOK_CLIENT_ID: process.env.NEXT_PUBLIC_FACEBOOK_CLIENT_ID,
  NEXT_PUBLIC_ICONO_PAY_USER_ID: safeNumber(
    process.env.NEXT_PUBLIC_ICONO_PAY_USER_ID || '',
  ),
  NEXT_PUBLIC_FIREBASE_API_KEY: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  NEXT_PUBLIC_FIREBASE_PROJECT_ID: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  NEXT_PUBLIC_FIREBASE_APP_ID: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  NEXT_PUBLIC_FIREBASE_DATABASE_URL:
    process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID:
    process.env.NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID,
  NEXT_PUBLIC_RAYGUN_API_KEY: process.env.NEXT_PUBLIC_RAYGUN_API_KEY,
  NEXT_PUBLIC_COMMIT_HASH: process.env.NEXT_PUBLIC_COMMIT_HASH,
  NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  NEXT_PUBLIC_TIDIO_KEY: process.env.NEXT_PUBLIC_TIDIO_KEY,
  NEXT_PUBLIC_RECAPTCHA_SITE_KEY: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY,
  NEXT_PUBLIC_POSTHOG_KEY: process.env.NEXT_PUBLIC_POSTHOG_KEY,
  NEXT_PUBLIC_POSTHOG_HOST: process.env.NEXT_PUBLIC_POSTHOG_HOST,
}
