import cc from 'classcat'
import Link from 'components/Link'
import IconArrow from 'assets/icons/arrow-payment.svg'
import Container from 'components/Container'
import { FC, useCallback } from 'react'
import withAside from 'hocs/withAside'
import { useAuth } from 'clients/auth.client'
import { useFeatureTesting } from 'stores/featureTesting.store'
import withRouterReady from 'hocs/withRouterReady'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'

type AsidePageMenuType = {
  to: string
  text: string
}

type UseMenu = () => AsidePageMenuType[] | (() => AsidePageMenuType[])

const AsidePageMenu = ({ useMenu }: { useMenu: UseMenu }) => {
  const router = useRouter()
  const menu = useMenu()

  return (
    <Container topAndBottom>
      <ul className="h-full bg-white bg-opacity-50 overflow-hidden rounded-lg">
        {(typeof menu === 'function' ? menu() : menu).map(
          ({ to, text }, index) => (
            <li key={`settings-menu-${index}`}>
              {index > 0 && <hr className="border-gray-400" />}
              <Link
                to={to}
                active={router.asPath === to}
                className={cc([
                  'flex items-center justify-between px-6 py-4 text-lg hover:bg-white',
                  {
                    'bg-white border-primary border-l-2 shadow-lg':
                      router.asPath.includes(to),
                  },
                ])}
              >
                <span>{text}</span>
                <IconArrow className="w-2" />
              </Link>
            </li>
          ),
        )}
      </ul>
    </Container>
  )
}

export default withRouterReady(AsidePageMenu)

export const withAsidePageMenu = (useMenu: UseMenu, Component: FC) =>
  withAside(Component, () => <AsidePageMenu useMenu={useMenu} />)

export const useProfileMenu = (): (() => AsidePageMenuType[]) => {
  const { account } = useAuth()
  const { t } = useTranslation()

  const links = useCallback(
    () =>
      [
        {
          to: '/profile/links',
          text: t<string>('menu:linkLinks'),
        },
        account?.isSeller && {
          to: '/profile/packages',
          text: t<string>('menu:linkPackages'),
        },
        account?.isSeller && {
          to: '/profile/demographics',
          text: t<string>('menu:linkAudience'),
        },
        {
          to: '/profile/edit',
          text: t<string>('menu:linkEditProfile'),
        },
      ].filter(Boolean) as AsidePageMenuType[],
    [account?.isSeller],
  )

  return links
}

export const useSettingsMenu = (): (() => AsidePageMenuType[]) => {
  const { account } = useAuth()
  const { t } = useTranslation()
  const proposalsFeatureTesting = useFeatureTesting('proposals')
  const adsFeatureTesting = useFeatureTesting('ads')
  const proFeatureTesting = useFeatureTesting('pro')
  const affiliateFeatureTesting = useFeatureTesting('affiliate')

  const links = useCallback(
    () =>
      [
        proposalsFeatureTesting.enabled && {
          to: '/settings/manage-proposals',
          text: t<string>('menu:linkManageProposals'),
        },
        {
          to: '/settings/legal-information',
          text: t<string>('menu:linkIdentityVerification'),
        },
        adsFeatureTesting.enabled && {
          to: '/settings/manage-ads',
          text: t<string>('menu:linkManageAds'),
        },
        {
          to: '/settings/credentials',
          text: t<string>('menu:linkCredentials'),
        },
        {
          to: '/billing#transactions',
          text: t<string>('menu:linkBilling'),
        },
        affiliateFeatureTesting.enabled && {
          to: '/partner',
          text: t<string>('menu:linkPartnerProgram'),
        },

        proFeatureTesting.enabled &&
          account?.subscription && {
            to: '/pro',
            text: t<string>('menu:linkProSubscription'),
          },
      ].filter(Boolean) as AsidePageMenuType[],
    [
      adsFeatureTesting.enabled,
      proposalsFeatureTesting.enabled,
      proFeatureTesting.enabled,
      account?.subscription,
    ],
  )

  return links
}

export const useAdmin = () => {
  return [
    {
      to: '/admin/users',
      text: 'Users',
    },
  ]
}
